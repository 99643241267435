function isMobile() {
  if (typeof window !== 'undefined') {
    return window && window.innerWidth < 768
  } else {
    return false
  }
}

function pickQuality(obj, keys = {sd: 'mp4UrlSd', hd: 'mp4Url'}) {
  const isMobile = ((typeof window !== 'undefined') && window.innerWidth < 768)
  return isMobile ? obj[keys.sd] ? obj[keys.sd] : obj[keys.hd] : obj[keys.hd]
}

function defaultSd(obj, keys = {sd: 'thumbnailMp4UrlSd', hd: 'thumbnailMp4Url'}) {
  return obj[keys.sd] ? obj[keys.sd] : obj[keys.hd]
}

export {
  isMobile,
  pickQuality,
  defaultSd
}
