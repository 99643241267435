import React from 'react'
import rebound from 'rebound'
import { bindAll } from 'lodash'

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.track = React.createRef()
    this.items = [] // array of refs

    bindAll(this, [
      'onMouseMove',
      'onMouseDown',
      'onMouseUp',
      'onMouseLeave',
      'onTouchStart',
      'onTouchMove',
      'onTouchEnd',
      'onSpringUpdate',
      'onSpringAtRest',
      'onResize'
    ])

    this.state = {
      dragging: false,
      dragged: false
    }

    this.diff = (nextState, state) => (key) => {
      return (nextState[key] !== state[key])
    }

    this.normFunc = function  (min, max, value) {
      return (value - min) / (max - min)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize)
    }
  }

  onResize() {
    this.getSize()
    this.spring.setCurrentValue(this.spring.getCurrentValue())
    this.spring.setAtRest()
  }

  componentDidMount() {
    this.endValue = 0
    this.count = this.items.length

    this.getSize()

    this.preloadFirstSlide()

    this.springSystem = new rebound.SpringSystem()
    this.spring = this.springSystem.createSpring()

    const config = rebound.SpringConfig.fromOrigamiTensionAndFriction(4.5, 5.7)
    this.spring.setSpringConfig(config)

    this.spring.addListener({
      onSpringUpdate: this.onSpringUpdate,
      onSpringAtRest: this.onSpringAtRest
    })
  }

  componentWillUnmount() {
    this.onEndTimeout && clearTimeout(this.onEndTimeout)
    this.spring.destroy()
    window.removeEventListener('resize', this.onResize)
  }

  preloadFirstSlide() {
    const img = this.items[0].querySelector('img')
    if (img) {
      if (img.classList.contains('lazyloaded')) {
        this.getSize()
      } else {
        img.addEventListener('lazybeforeunveil', () => {
          this.getSize()
        })
      }
    }
  }

  getSize () {
    const { width } = this.items[0].getBoundingClientRect()
    this.unitWidth = width
    this.trackWidth = width * this.count
    this.screenWidth = window.innerWidth

    const boundings = this.track.current.getBoundingClientRect()
    this.width = boundings.width
    this.norm = this.normFunc.bind(null, 0, this.unitWidth * -1)
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    return shouldUpdate
  }

  stateClassName() {
    let className = ''
    return className
  }

  onSpringUpdate (spring) {
    const progress = spring.getCurrentValue()

    this.track.current.style.transform = `translate3d(${progress * this.unitWidth * -1}px, 0, 0)`
  }

  onSpringAtRest (spring) {}

  startDragging (x) {
    this.setState({dragging: true})
    this.lastX = x
    this.spring.setAtRest()
    this.visibleCount = Math.floor(this.screenWidth / this.unitWidth)
  }

  continueDragging (x) {
    this.setState({dragged: true})
    this.panVelocity = x - this.lastX
    this.lastX = x

    let progress = this.norm(this.panVelocity)
    const currentValue = this.spring.getCurrentValue()

    // Rubberband when beyond the scroll boundaries
    if (
      currentValue + progress < 0 ||
      currentValue + progress > this.count - this.visibleCount
    ) {
      progress *= 0.25
    }

    this.spring.setCurrentValue(currentValue + progress)
    this.spring.setAtRest()
  }

  endDragging () {
    const currentValue = this.spring.getCurrentValue()
    const restPosition = this.endValue

    const passedVelocityTolerance = Math.abs(this.panVelocity) > 3
    const passedDistanceTolerance = Math.abs(currentValue - restPosition) > 0.3

    const shouldAdvance = passedDistanceTolerance || passedVelocityTolerance
    const advanceForward = this.panVelocity <= 0

    if (shouldAdvance) {
      const round = advanceForward ? 'ceil' : 'floor'
      const destination = Math.min(Math[round](currentValue), this.count - this.visibleCount)
      this.goTo(destination)
    } else {
      this.goTo(restPosition)
    }

    const normalizedVelocity = this.norm(this.panVelocity)
    this.spring.setVelocity(normalizedVelocity * 30)
    this.panVelocity = 0

    this.setState({dragging: false})
  }

  goTo (index) {
    if (index < 0) {
      index = 0
    } else if (index >= this.count) {
      index = this.count - 1
    }

    this.endValue = index
    this.spring.setEndValue(index)
  }

  onMouseDown(e) {
    e.preventDefault()
    this.startDragging(e.clientX)
  }

  onMouseMove(e) {
    if (this.state.dragging) {
      e.preventDefault()
      this.continueDragging(e.clientX)
    }
  }

  onTouchStart(e) {
    const pageX = e.touches[0].pageX
    this.ts = pageX
    this.startDragging(pageX)
  }

  onTouchMove(e) {
    const pageX = e.touches[0].pageX
    if (Math.abs(this.ts - pageX) > 20) {
      this.continueDragging(pageX)
    }
  }

  onMouseUp() { this._onEnd() }
  onMouseLeave() { this._onEnd() }
  onTouchEnd() { this._onEnd() }
  onTouchCancel() { this._onEnd() }

  _onEnd() {
    if (this.state.dragging) {
      this.endDragging()
      this.onEndTimeout = setTimeout(() => {
        this.setState({dragged: false})
      }, 400)
    }
  }

  setItemRef = (ref) => {
    this.items.push(ref)
  }

  render() {
    return (
      <div className={`slider ${this.props.className} ${this.stateClassName()}`} onMouseDown={this.onMouseDown} onMouseMove={this.onMouseMove} onMouseUp={this.onMouseUp} onMouseLeave={this.onMouseLeave} onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} onTouchEnd={this.onTouchEnd} onTouchCancel={this.onTouchCancel} ref={this.slider}>

        <div className="slider__track" ref={this.track} style={{width: `${this.trackWidth}px`}}>
          {
            this.props.children.map((child, i) => (
              <div key={i} className="slider__item" ref={this.setItemRef}>{child}</div>
            ))
          }
        </div>

      </div>
    )
  }
}

export default Slider