import React from 'react'

export const PreviewsContext = React.createContext()

class Previews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onMouseEnterPreview: (preview) => {
        if (this.active && this.active.uId !== preview.uId) {
          this.active.pause()
        }
        this.active = preview
      }
    }
  }
  render() {
    return (
      <PreviewsContext.Provider value={this.state}>
        {this.props.children}
      </PreviewsContext.Provider>
    )
  }
}

export default Previews