import React from 'react'

function no_space(letter) {
  return letter === ' ' ? '&nbsp' : letter
}

function Group({text}) {
  const hasMore = (text.length > 2)
  const hasEnd = (text.length >= 2)
  return (
    <span className="parent">
      <span className="group">
        <span className="letter" dangerouslySetInnerHTML={{__html: no_space(text[0])}}></span>
        {
          hasMore ? <Group text={text.slice(1, text.length-1)} /> : ''
        }
        {
          hasEnd ? <span className="letter" dangerouslySetInnerHTML={{__html: no_space(text[text.length-1])}}></span> : ''
        }
      </span>
    </span>
  )
}

class Split extends React.Component {
  render() {
    const t = this.props.children
    return (
      <span className="split">
        <Group text={t} />
      </span>
    )
  }
}

export default Split