import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import {isMobile} from "../../utils/pick-quality";

class Fade extends React.Component {
  render() {
    return (
      <TransitionLink
        to={this.props.to}
        exit={{
          length: this.props.exit,
          trigger: () => {
            if (isMobile() && (this.props.className && this.props.className.includes('refresh'))) {
              window.location = this.props.to
            }
            delete window.video
          }
        }}
        entry={{
          trigger: () => {
            try {
              setTimeout(() => {
                if (typeof window === 'undefined' || !window.video) return true
                window.video.play()
                delete window.video
              }, 800)
            } catch (e) {
              console.log('fade: trigger()', e)
            }
          },
          length: this.props.entry
        }}

        className={this.props.className}
        activeClassName="is-active"
        onClick={this.props.onClick}
        partiallyActive
      >
        {this.props.children}
      </TransitionLink>
    )
  }
}

Fade.defaultProps = {
  entry: .4,
  exit: .4
}

export default Fade