import React from 'react'
import prefix from 'prefix-style'
import objectFit from '../../utils/object-fit'

class ObjectFit extends React.Component {
  constructor(props) {
    super(props)
    this.el = React.createRef();
  }
  componentDidMount() {
    this.objectfit = new objectFit({
      prefix: prefix('transform'),
      container: this.el.current,
      el: this.el.current.children[0]
    })
    this.objectfit.update()
  }
  fit() {
    this.objectfit.update()
  }
  render() {
    return <div ref={this.el} className={`object-fit ${this.props.className}`}>{this.props.children}</div>
  }
}

export default ObjectFit