import React from 'react'

import { Fade } from './index.js'
import logo from 'raw-loader!../assets/logo_svg.txt'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleClass= this.toggleClass.bind(this);
    this.state = {
      active: false,
      currentPath: props.pathname ? props.pathname : false,
      isIndex: (props.pathname === '/')
    };
    this.menu = {
      '/directors/': 'Directors',
      '/work/': 'Work',
      '/print/': 'Print',
      '/cinema/': 'Cinema',
      '/contact/': 'Contact'
    }
  }

  toggleClass() {
    const currentState = this.state.active
    this.setState({ active: !currentState })
    this.props.onToggle(!currentState)
  }

  renderLogo() {
    return this.props.isIndex ? (
      <h1 className="text-0" dangerouslySetInnerHTML={{__html: logo }}></h1>
    ) : (<span dangerouslySetInnerHTML={{__html: logo }}></span>)
  }

  render() {
    return (
      <div className="header">
        <div className="wrapper">
          <div className="flex justify-between">
            <div className="header__logo">
              <Fade to="/">
                {this.renderLogo()}
              </Fade>
            </div>
            <div className={`header__nav ${this.state.active ? 'is-active': null}`}>
              <nav className="header__menu">
                <ul>
                  {
                    Object.keys(this.menu).map((path) => {
                      return (<li key={path}><Fade to={`${path}`}><span className="uppercase text-14 md:text-13">{this.menu[path]}</span></Fade></li>)
                    })
                  }
                </ul>
              </nav>

              <div className="header__menu-btn">
                <div onClick={this.toggleClass}>
                  <span></span>
                </div>
              </div>

              <div className="header__current"><span className="uppercase text-14 md:text-13">{this.menu[this.state.currentPath]}</span></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header