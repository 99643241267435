import React from 'react'
import { bindAll, uniqueId } from 'lodash'


import ObjectFit from './object-fit'
import preload from '../../utils/preload'

import { PreviewsContext } from './previews'
import {isMobile} from "../../utils/pick-quality";

class Preview extends React.Component {
  constructor(props) {
    super(props)
    this.el = React.createRef()
    this.video = React.createRef()
    this.objectFit = React.createRef()

    this.uId = uniqueId('preview')

    bindAll(this, ['onLoadedMetaData', 'onLoadedData', 'onMouseEnter', 'onCanPlay'])

    this.state = {
      enable: false,
      ready: false,
      playing: false,
      canPlay: false,
      hasPlayed: false,
      preloaded: false
    }

    this.diff = (nextState, state) => (key) => {
      return (nextState[key] !== state[key])
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', this.onMouseMove.bind(this), {once: true})
    }
  }

  onMouseMove() {
    this.setState({enable: true})
  }

  _togglePlay(play) {
    const { current } = this.video
    if (!current) return
    const action = (play) ? 'play' : 'pause'
    current[action]()
    // play comes after 'canplay' event
    if (play && this.state.canPlay) {
      this.playTimeoutId = setTimeout(() => {
        // delay to prevent black video flashing
        this.setState({hasPlayed: true})
      }, 300)
    }
  }

  onLoadedMetaData() {
    this.setState({ready: true}, () => {
      this.objectFit.current.fit()
    })
    this.props.onReady && this.props.onReady()
  }

  onLoadedData() {
    this.objectFit.current.fit()
    // this.setState({ready: true}, () => {
    //   this.objectFit.current.fit()
    // })
    // this.props.onReady && this.props.onReady()
  }

  onCanPlay() {
    this.setState({canPlay: true}, () => {
      if (this.state.playing) {
        // canPlay comes after 'play' trigger
        this.canPlayTimeoutId = setTimeout(() => {
          // delay to prevent black video flashing
          this.setState({hasPlayed: true})
        }, 3000)
      } else if (this.props.autoPlay) {
        this.play()
      }
    })
  }

  onMouseEnter() {
    if (!this.state.enable) return true
    this.play()
  }

  play() {
    this.setState({playing: true})
  }

  pause() {
    this.setState({playing: false})
  }

  componentDidMount() {
    if (this.props.cover) {
      preload(this.props.cover).then((src) => {
        this.setState({preloaded: true})
      })
    }
  }

  componentWillUnmount() {
    this.canPlayTimeoutId && clearTimeout(this.canPlayTimeoutId)
    this.playTimeoutId && clearTimeout(this.playTimeoutId)
    window.removeEventListener('mousemove', this.onMouseMove)
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    const diff = this.diff(nextState, this.state)
    if (diff('playing') || diff('ready')) {
      this._togglePlay(nextState.playing && nextState.ready)
      shouldUpdate = true
    }
    if (diff('preloaded')) {
      shouldUpdate = true
    }
    if (diff('hasPlayed')) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  attrs() {
    return { autoPlay: this.props.autoPlay }
  }

  stateClassName() {
    let className = ''
    if (this.props.cover) {
      className += ' has-cover'
    }
    className += (this.state.ready) ? ' is-ready' : ''
    className += (this.state.preloaded) ? ' is-preloaded' : ''
    className += (this.state.hasPlayed) ? ' has-played' : ''
    return className
  }

  render() {
    const hasBlur = (typeof this.props.blur !== 'undefined')
    const hasVideo = (!isMobile() || this.props.mobileVideo)
    return (
      <PreviewsContext.Consumer>
        {
          ({onMouseEnterPreview}) => {
            return (
              <div className={`preview ${this.props.className} ${this.stateClassName()}`}
                   onMouseEnter={() => { this.onMouseEnter(); onMouseEnterPreview(this)}} ref={this.el}>
                <ObjectFit className="preview__bg h-full" ref={this.objectFit}>
                  {
                    hasVideo ?
                      <video src={this.props.src} loop playsInline ref={this.video}
                         onLoadedMetadata={this.onLoadedMetaData} onLoadedData={this.onLoadedData}
                         onCanPlay={this.onCanPlay} {...this.attrs()} preload={this.props.preload} autoPlay={this.props.autoPlay} muted></video> : ''
                  }
                  <div className="preview__cover" style={{backgroundImage: `url(${this.props.cover})`}}></div>
                  {
                    hasBlur ? <div className="preview__blur" style={{backgroundImage: `url(${this.props.blur})`}}></div> : ''
                  }
                </ObjectFit>

                <div className="preview__title">
                  <div>
                    {this.props.children}
                  </div>
                </div>
              </div>
            )
          }
        }
      </PreviewsContext.Consumer>
    )
  }
}

Preview.defaultProps = {
  preload: ''
}

export default Preview