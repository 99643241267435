class ObjectFit {
  constructor({...args}) {
    const props = {
      prefix: 'transform',
      container: document.body,
      size: 'cover'
    }

    this.el = args.el
    this.props = Object.assign(props, {...args})

    window.addEventListener('resize', () => {
      this.update()
    })

    return this
  }

  init() {
    this.getRatios()

    const pos = {scale: 1, x: 0, y: 0}

    if (this.props.size === 'cover' ? (this.containerRatio > this.elRatio) : (this.containerRatio < this.elRatio)) {
      pos.scale = this.containerBounds.width/this.elBounds.width
      pos.y = -((this.elBounds.height*pos.scale)-this.containerBounds.height)/2
    } else {
      pos.scale = this.containerBounds.height/this.elBounds.height
      pos.x = -((this.elBounds.width*pos.scale)-this.containerBounds.width)/2
    }

    this.apply(pos)
  }

  getRatios() {
    this.containerBounds = this.props.container.getBoundingClientRect()
    this.containerRatio = this.containerBounds.width/this.containerBounds.height

    this.elBounds = this.el.getBoundingClientRect()
    this.elRatio = this.elBounds.width/this.elBounds.height
  }

  apply(pos) {
    this.el.style[this.props.prefix] = `translate3d(${pos.x}px, ${pos.y}px, 0) scale(${pos.scale})`
  }

  update() {
    this.apply({scale: 1, x: 0, y: 0})
    this.init()
  }

}

export default ObjectFit