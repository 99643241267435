import React from 'react'
import { bindAll } from 'lodash'

class Swipe extends React.Component {
  constructor(props) {
    super(props)

    bindAll(this, ['onTouchStart', 'onTouchMove', 'onTouchEnd'])
  }

  maybeSwipe() {
    const diff = this.end - this.start
    if (Math.abs(diff) > this.props.threshold) {
      this.props.onSwipe(diff > 0 ? 'left' : 'right')
    }
  }

  onTouchStart(e) {
    this.start = e.touches[0].pageX
  }

  onTouchMove(e) {
    this.end = e.touches[0].pageX
  }

  onTouchEnd(e) {
    this.maybeSwipe()
  }

  render() {
    return (
      <div className="swipe h-full" onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onTouchMove={this.onTouchMove}>
        {this.props.children}
      </div>
    )
  }
}

Swipe.defaultProps = {
  threshold: 75
}

export default Swipe
