import React from 'react'
import { Slider, WithStore } from 'pure-react-carousel'

class Carousel extends React.Component {

  constructor(props) {
    super(props)
    this.slider = React.createRef()
  }

  componentWillReceiveProps (nextProps) {
    const { currentSlide, onChangeCurrentSlide } = this.props
    if (nextProps.currentSlide !== currentSlide && onChangeCurrentSlide) {
      this.currentDir = (nextProps.currentSlide > this.props.currentSlide) ? 'forward' : 'backward'
      onChangeCurrentSlide(nextProps.currentSlide)
    }
  }

  play() {
    this.slider.current.instance.play()
  }

  stop() {
    this.slider.current.instance.stop()
  }
  
  prev() {
    const { currentSlide, totalSlides } = this.props
    this.props.carouselStore.setStoreState({currentSlide: (currentSlide === 0) ? totalSlides - 1 : currentSlide - 1})
  }
  
  next() {
    const { currentSlide, totalSlides } = this.props
    this.props.carouselStore.setStoreState({currentSlide: (currentSlide === totalSlides - 1) ? 0 : currentSlide + 1})
  }

  render () {
    return (
      <Slider className={this.props.className} classNameAnimation={`${this.props.classNameAnimation} ${this.currentDir}`} classNameTray={this.props.classNameTray} ref={this.slider}>
        {this.props.children}
      </Slider>
    )
  }
}

export default WithStore(Carousel, state => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
  playDirection: state.playDirection,
}))