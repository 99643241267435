import React from 'react'
import { debounce, throttle, bindAll } from 'lodash'

import preload from '../../utils/preload'
import hasFullscreen from '../../utils/test-fullscreen'

import expand from '../assets/expand.svg'
import reduce from '../assets/reduce.svg'
import fullscreen from '../assets/fullscreen.svg'
import inline from '../assets/inline.svg'
import play from '../assets/play.svg'
import pause from '../assets/pause.svg'
import muted from '../assets/muted.svg'
import sound from '../assets/sound.svg'
import close from '../assets/close.svg'
import {isMobile} from "../../utils/pick-quality";

const ESCAPE_KEY = 27;

class Player extends React.Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.player = React.createRef()
    this.timeline = React.createRef()
    this.currentTime = React.createRef()
    this.seekBar = React.createRef()

    bindAll(this, [
      'onMouseMove',
      'onClickVideo',
      'onClickPlayPause',
      'onClickToggleMute',
      'onClickExpand',
      'onClickFullscreen',
      'onClickInline',
      'onMouseDownSeekbar',
      'onMouseMoveSeekbar',
      'onMouseUpSeekbar',
      'onTouchStartSeekbar',
      'onTouchMoveSeekbar',
      'onTouchEndSeekbar',
      'onLoadedMetaData',
      'onTimeUpdate',
      'onEnded',
      'onPlay',
      'onPause',
      'onFullscreenChange',
      'onKeydown'
    ])

    this.onMouseMove = throttle(this.onMouseMove, 600)
    this._hideControls = debounce(this._hideControls, 1400)

    this.modifiers = []
    if (this.props.modifiers) {
      const modifiers = [...this.props.modifiers].map(modifier => `player--${modifier}`)
      this.modifiers = modifiers.join(' ')
    }

    this.state = {
      preloaded: false,
      loadedMetaData: false,
      autoPlay: true,
      play: false,
      playing: false,
      hasPlayed: false,
      controls: true,
      duration: '00:00',
      durationValue: 0,
      currentTime: 0,
      progress: 0,
      expanded: false,
      fullscreen: false,
      muted: isMobile(),
      hasFullscreen: false
    }

    if (this.props.autoPlay !== undefined) {
      this.state.autoPlay = this.props.autoPlay
    }

    this.diff = (nextState, state) => (key) => {
      return (nextState[key] !== state[key])
    }

    this.format = (seconds) => {
      const pad = (num) => {
        const t = `0${num}`
        return t.substr(t.length-2);
      }
      seconds = Math.round(seconds)
      if (seconds < 60) {
        return `00:${pad(seconds)}`
      }
      const minutes = Math.floor(seconds / 60)
      seconds = seconds - minutes * 60
      return `${pad(minutes)}:${pad(seconds)}`
    }

    if (this.props.modifiers && this.props.modifiers.includes('fullscreen')) {
      this._hideControls()
    }
  }

  componentWillUnmount() {
    this.onMouseMove.cancel()
    this._hideControls.cancel()
    document.removeEventListener(this.fullscreenEvent, this.onFullscreenChange)
    document.removeEventListener('keydown', this.onKeydown)
  }

  componentDidMount() {
    if (this.props.cover) {
      preload(this.props.cover).then((src) => {
        this.setState({preloaded: true})
      })
    }

    this.setState({hasFullscreen: hasFullscreen()})

    const event = ['onfullscreenchange', 'onmsfullscreenchange', 'onmozfullscreenchange', 'onwebkitfullscreenchange'].find((eventName) => (document[eventName] === null))
    if (event) {
      this.fullscreenEvent = event.slice(2)
      document.addEventListener(this.fullscreenEvent, this.onFullscreenChange)
    } else {
      document.addEventListener('webkitendfullscreen', this.onFullscreenChange, false)
    }
    document.addEventListener('keydown', this.onKeydown)
  }

  onFullscreenChange() {
    const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
    this.setState({fullscreen: isFullScreen})
  }

  onKeydown(e) {
    switch(e.keyCode) {
      case ESCAPE_KEY:
        if (this.state.expanded) {
          this._toggleExpand(false)
        }
        break
      default:
        break
    }
  }

  _togglePlay(play) {
    if (this.state.loadedMetaData) {
      const { current } = this.video
      if (play) {
        var isPlaying = current.currentTime > 0 && !current.paused && !current.ended && current.readyState > 2
        if (!isPlaying) {
          const playPromise = current.play()
          playPromise
            .then(() => {
              // do nothing
            })
            .catch(e => {
              console.log('player: ()', e.message)
            })
        }
      } else {
        current.pause()
      }
    }
    this.setState({ play })
    if (play) {
      this._hideControls()
    }
  }

  _seek(time) {
    const { current } = this.video
    current.currentTime = time
  }

  _showControls() {
    this.setState({controls: true})
    this._hideControls()
  }

  _hideControls() {
    if (this.state.playing) {
      this.setState({controls: false})
    }
  }

  _toggleFullscreen(fullscreen) {
    if (fullscreen) {
      const { current } = this.player
      if (current.requestFullscreen) {
        current.requestFullscreen()
      } else if (current.mozRequestFullScreen) {
        current.mozRequestFullScreen()
      } else if (current.webkitRequestFullscreen) {
        current.webkitRequestFullscreen()
      }
    } else {
      document.exitFullscreen()
    }
  }

  _toggleExpand(expanded) {
    this.setState({expanded})
  }

  setCurrentTime(mouseX) {
    const progress = (mouseX - this.seekbar.x) / this.seekbar.width
    this.setState({currentTime: this.state.durationValue * progress})
    this._showControls()
  }

  playPause() {
    (!this.state.playing) ? this.play() : this.pause()
  }

  play() {
    this._togglePlay(true)
  }

  pause() {
    this._togglePlay(false)
  }

  backInline() {
    if (this.state.fullscreen) {
      this._toggleFullscreen(false)
    } else if (this.state.expanded) {
      this._toggleExpand(false)
    }
  }

  startDragging(userX) {
    this.dragging = true
    const { x, width } = this.seekBar.current.getBoundingClientRect()
    this.seekbar = { x, width }
    this.setCurrentTime(userX)
  }
  endDragging() {
    this.dragging = false
  }

  onMouseMove() {
    !isMobile() && this._showControls(true)
  }

  onClickVideo() {
    if (this.state.controls) {
      this.playPause()
    } else {
      this._showControls(true)
    }
  }

  onClickPlayPause() {
    this.playPause()
  }

  onClickToggleMute() {
    this.setState({ muted: !this.state.muted })
  }

  onClickFullscreen() {
    this._toggleFullscreen(!this.state.fullscreen)
  }

  onClickExpand() {
    this._toggleExpand(!this.state.expanded)
  }

  onClickInline() {
    this.backInline()
  }

  onMouseDownSeekbar(e) { this.startDragging(e.clientX) }
  onTouchStartSeekbar(e) { this.startDragging(e.touches[0].pageX) }

  onMouseMoveSeekbar(e) {
    if (this.dragging) {
      this.setCurrentTime(e.clientX)
    }
  }
  onTouchMoveSeekbar(e) {
    if (this.dragging) {
      this.setCurrentTime(e.touches[0].pageX)
    }
  }

  onMouseUpSeekbar() { this.endDragging() }
  onTouchEndSeekbar() { this.endDragging() }

  onLoadedMetaData(e) {
    const { current } = this.video
    const { duration } = current
    this.setState({
      duration: this.format(duration),
      durationValue: duration,
      loadedMetaData: true
    }, () => {
      if (this.state.play) {
        this._togglePlay(true)
      }
    })
  }

  onTimeUpdate() {
    const { current } = this.video
    const { currentTime } = current

    const ratio = (1/current.duration)*currentTime
    const progress = `scale3d(${ratio}, 1, 1)`

    this.timeline.current.style.transform = progress
    this.currentTime.current.innerHTML = this.format(currentTime)
  }

  onPlay() {
    const state = { playing: true }
    if (!this.state.hasPlayed) {
      state.hasPlayed = true
    }
    this.setState(state)
  }

  onPause() {
    this.setState({ playing: false })
  }

  onEnded() {
    this.setState({play: false, controls: true})
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    const diff = this.diff(nextState, this.state)
    if (diff('playing')) {
      shouldUpdate = true
    }
    if (diff('currentTime')) {
      this._seek(nextState.currentTime)
      shouldUpdate = true
    }
    if (diff('expanded')) {
      shouldUpdate = true
      document.body.classList[nextState.expanded ? 'add' : 'remove']('has-player-extanded')
    }
    if (diff('fullscreen')) {
      shouldUpdate = true
    }
    if (diff('controls') || diff('progress') || diff('duration')) {
      shouldUpdate = true
    }
    if (diff('preloaded')) {
      shouldUpdate = true
    }
    if (diff('muted')) {
      shouldUpdate = true
    }
    if (diff('hasFullscreen')) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  stateClassName() {
    let className = ''
    const { playing, controls, expanded, fullscreen, muted } = this.state
    if ( playing ) {
      className += ' is-playing'
    }
    if ( controls ) {
      className += ' show-controls'
    }
    if ( expanded ) {
      className += ' is-expanded'
    }
    if ( fullscreen ) {
      className += ' is-fullscreen'
    }
    if ( muted ) {
      className += ' is-muted'
    }
    if (this.props.cover) {
      className += ' has-cover'
    }
    className += (this.state.preloaded) ? ' is-preloaded' : ''
    className += (this.state.hasPlayed) ? ' has-played' : ''
    className += (this.state.hasFullscreen) ? '' : ' no-fullscreen'
    return className
  }

  render() {
    return (<div className={`player ${this.props.className} ${this.modifiers} ${this.stateClassName()}`} onMouseMove={this.onMouseMove} ref={this.player}>
      <div className="player__video">
        <video src={this.props.src} ref={this.video} onLoadedMetadata={this.onLoadedMetaData} onTimeUpdate={this.onTimeUpdate} onEnded={this.onEnded} onClick={this.onClickVideo} onPlay={this.onPlay} onPause={this.onPause} playsInline muted={this.state.muted} autoPlay={this.state.autoPlay}></video>

        <div className="player__cover" style={{backgroundImage: `url(${this.props.cover})`}}></div>
      </div>
      <div className="player__overlay">
        {this.props.children}
      </div>
      <div className="player__controls">
        <div>
          <div className="flex items-center">
            <button className="player__play-pause" onClick={this.onClickPlayPause}>
              <span><img src={pause} width="13" alt="pause"/></span>
              <span><img src={play} width="13" alt="play"/></span>
            </button>
            <div className="player__time"><span ref={this.currentTime}>00:00</span> / {this.state.duration}</div>
          </div>
          <div className="player__seek-bar" ref={this.seekBar} onMouseDown={this.onMouseDownSeekbar} onMouseMove={this.onMouseMoveSeekbar} onMouseUp={this.onMouseUpSeekbar} onTouchStart={this.onTouchStartSeekbar} onTouchMove={this.onTouchMoveSeekbar} onTouchEnd={this.onTouchEndSeekbar}>
            <div className="player__timeline">
              <span ref={this.timeline}></span>
            </div>
          </div>
          <div className="flex">
            <button className="player__toggle-mute" onClick={this.onClickToggleMute}>
              <span><img src={sound} width="17" alt="mute"/></span>
              <span><img src={muted} width="17" alt="sound on"/></span>
            </button>
            {
              (()=>{
                if (this.props.hasExpand && !this.state.fullscreen) {
                  return (
                    <div className="player__expand">
                      <button onClick={this.onClickExpand}>
                        <span><img src={expand} alt="Expand player" width="28"/></span>
                        <span><img src={reduce} alt="Reduce player" width="28"/></span>
                      </button>
                    </div>
                  )
                }
              })()
            }
            <div className="player__fullscreen">
              <button onClick={this.onClickFullscreen}>
                <span><img src={fullscreen} alt="Enter fullscreen" width="28"/></span>
                <span><img src={inline} alt="Exit fullscreen" width="28"/></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="player__inline-btn" onClick={this.onClickInline}>
        <img src={close} alt="close"/>
      </div>
    </div>)
  }
}

export default Player