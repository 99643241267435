import React from 'react'
import 'lazysizes'
import { TransitionState } from 'gatsby-plugin-transition-link'

import { Header, SEO } from './index'

import '../styles/main.styl'

class Layout extends React.Component {

  constructor(props) {
    super(props)

    this.header = React.createRef()

    this.onHeaderToggle = this.onHeaderToggle.bind(this)
    this.onClickLayout = this.onClickLayout.bind(this)

    this.state = {
      menuVisible: false
    }
  }

  onHeaderToggle(active) {
    this.setState({ menuVisible: active })
  }

  onClickLayout(e) {
    if (this.state.menuVisible) {
      this.header.current.toggleClass()
      e.preventDefault()
    }
  }

  modifiers() {
    return this.props.modifiers && this.props.modifiers.map(modifier => `layout--${modifier}`).join(' ')
  }

  render() {
    const title = this.props.page.props.pathContext.title || this.props.page.title
    const { pathname } = this.props.page.props.location
    return (
      <div className={`layout ${this.modifiers()}`}>
        <SEO pathname={pathname} title={title}/>
        <Header pathname={pathname} onToggle={this.onHeaderToggle} ref={this.header}/>
        <div className={`layout__content ${this.state.menuVisible ? 'has-menu' : ''}`} onClick={this.onClickLayout}>
          <TransitionState>
            {({ transitionStatus }) => {
              return (
                <div className={`fade-${transitionStatus}`}>
                  {this.props.children}
                </div>
              )
            }}
          </TransitionState>
        </div>
      </div>
    )
  }
}

export default Layout